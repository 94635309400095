<template>
  <v-bottom-sheet fullscreen v-model="alwaystrue">
    <v-sheet class="pt-4" height="100vh" style="overflow-y: scroll">
      <v-img
        :src="require('@/assets/reservation_bg.png')"
        contain
        style="position: absolute; top: 0; right: 0"
      ></v-img>
      <v-card elevation="0" class="px-3" style="background: transparent">
        <v-card-title class="HeadingDarkH3-22Center mt-12 pb-0 justify-center">
          {{ $t("reservation.thanks") }}
        </v-card-title>
        <v-btn icon color="#3acc6c" class="mx-auto mt-5" style="width: 100%"
          ><v-icon size="72">mdi-check-bold</v-icon></v-btn
        >
        <div class="Heading-Dark-H4-18-Center mt-8">
          {{ $t("reservation.tableBooked") }}
        </div>
        <div
          class="Text-Dark-Text-3---14-Left-Align text-center mt-4"
          v-html="$t('reservation.findReservation')"
        ></div>
      </v-card>
      <v-card elevation="0" class="mt-8">
        <v-divider></v-divider>
        <v-list class="px-8">
          <v-list-item dense>
            <v-list-item-icon size="24" class="mr-2">
              <img
                class="color-filter"
                width="24"
                height="24"
                :src="require('@/assets/reservation/calendar_active.svg')"
              />
            </v-list-item-icon>
            <v-list-item-title class="Heading-Dark-H5-16-Left-Align">{{
              $store.state.reserveout.date
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item dense v-if="$store.state.reserveout.time">
            <v-list-item-icon size="24" class="mr-2">
              <img
                class="color-filter"
                width="24"
                height="24"
                :src="require('@/assets/reservation/time_active.svg')"
              />
            </v-list-item-icon>
            <v-list-item-title class="Heading-Dark-H5-16-Left-Align">{{
              $store.state.reserveout.time
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item dense v-if="$store.state.reserveout.covers">
            <v-list-item-icon size="24" class="mr-2">
              <img
                class="color-filter"
                width="24"
                height="24"
                :src="require('@/assets/reservation/people_active.svg')"
              />
            </v-list-item-icon>
            <v-list-item-title class="Heading-Dark-H5-16-Left-Align"
              >{{ $store.state.reserveout.covers }}
              {{
                $store.state.reserveout.covers === 1
                  ? $t("reservation.guest")
                  : $t("reservation.guests")
              }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </v-card>
      <v-card elevation="0" class="py-8">
        <v-list class="px-8">
          <!-- commented as not available yet‚
          <v-list-item dense>
            <v-list-item-icon size="24" class="mr-2">
              <img
                width="24"
                height="24"
                :src="require('@/assets/reservation/view.svg')"
                :class="$store.state.settings.dark ? 'filter' : ''"
              />
            </v-list-item-icon>
            <v-list-item-title class="Text-Dark-Text-1---18-Left-Align">
              {{ $t("reservation.viewMenu") }}</v-list-item-title
            >
          </v-list-item>
          -->
          <v-list-item dense @click="$emit('modify')">
            <v-list-item-icon size="24" class="mr-2">
              <img
                width="24"
                height="24"
                :src="require('@/assets/reservation/modify.svg')"
                :class="$store.state.settings.dark ? 'filter' : ''"
              />
            </v-list-item-icon>
            <v-list-item-title class="Text-Dark-Text-1---18-Left-Align">
              {{ $t("reservation.modifyBooking") }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item dense @click="$emit('cancel')">
            <v-list-item-icon size="24" class="mr-2">
              <img
                width="24"
                height="24"
                :src="require('@/assets/reservation/cancel.svg')"
                :class="$store.state.settings.dark ? 'filter' : ''"
              />
            </v-list-item-icon>
            <v-list-item-title class="Text-Dark-Text-1---18-Left-Align">
              {{ $t("reservation.cancelBooking") }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn
            width="150"
            height="48"
            depressed
            color="primary"
            class="gradient mt-5 Heading-White-H4-18-Center mx-auto"
            @click="$emit('done')"
          >
            {{ $t("reservation.done") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "BookingConfirmation",
  data: () => ({
    alwaystrue: true,
  }),
};
</script>
