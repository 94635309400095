<template>
  <v-bottom-sheet v-model="alwaystrue" content-class="profile-sheet"
    ><v-sheet
      class="pt-4 px-6"
      height="85vh"
      style="
        padding-bottom: 80px;
        overflow: scroll;
        border-radius: 30px 30px 0px 0px;
      "
    >
      <v-toolbar height="38px" flat>
        <!-- div with the width of the close button to put the image in the middle -->
        <div style="width: 48px"></div>
        <v-spacer></v-spacer>
        <img
          :style="
            $store.state.settings.dark
              ? 'margin-left: -30px; margin-top: 10px;'
              : 'margin-top: 10px;'
          "
          width="90"
          style="background-size: contain !important"
          height="52"
          :src="
            $store.state.settings.dark
              ? require('@/assets/img/poco-01-2.svg')
              : require('@/assets/img/poco-01-2.svg')
          " />
        <v-spacer></v-spacer
        ><v-btn text @click="$emit('close')" icon
          ><v-img
            width="24"
            height="24"
            contain
            :src="require('@/assets/reservation/close.svg')"
            :class="$store.state.settings.dark ? 'filter' : ''"
          ></v-img></v-btn
      ></v-toolbar>
      <div v-if="signUp" class="HeadingDarkH3-22Center mt-3">
        {{ $t("onboarding.signUpToContinue") }}
      </div>
      <div v-else class="HeadingDarkH3-22Center mt-3">
        {{ $t("reservation.completeProfile") }}
      </div>
      <div class="Heading-Dark-H4-18-Left-Align mt-6">
        {{ $t("reservation.contactInformation") }}
      </div>
      <v-card style="border-radius: 10px !important" class="mt-1">
        <v-form ref="form">
          <v-text-field
            solo
            flat
            :hide-details="firstNameErrors.length === 0"
            :error-messages="firstNameErrors"
            :placeholder="$t('profile.firstName') + '*'"
            maxlength="50"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
          ></v-text-field>
          <v-divider></v-divider>
          <v-text-field
            solo
            flat
            :hide-details="lastNameErrors.length === 0"
            :error-messages="lastNameErrors"
            :placeholder="$t('profile.lastName') + '*'"
            maxlength="50"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
          ></v-text-field>
          <v-divider></v-divider>
          <v-text-field
            solo
            flat
            :hide-details="phoneErrors.length === 0"
            :error-messages="phoneErrors"
            :placeholder="$t('profile.phone') + '*'"
            maxlength="50"
            v-model="phone"
            :hint="$t('profile.phoneNumberHint')"
            persistent-hint
            @change="phone ? (phone = phone.trim()) : ''"
            @blur="$v.phone.$touch()"
          ></v-text-field>
          <div v-if="signUp">
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              :hide-details="emailErrors.length === 0"
              :error-messages="emailErrors"
              :placeholder="$t('profile.email') + '*'"
              maxlength="100"
              v-model="email"
              persistent-hint
              @blur="$v.email.$touch()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              :hide-details="pw1Errors.length === 0"
              :placeholder="$t('profile.password') + '*'"
              v-model="pw1"
              :error-messages="pw1Errors"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              maxlength="50"
              @click:append="showPassword = !showPassword"
              @blur="$v.pw1.$touch()"
            ></v-text-field>
            <v-divider></v-divider>
            <v-text-field
              solo
              flat
              :hide-details="pw2Errors.length === 0"
              :placeholder="$t('profile.confirmPassword') + '*'"
              v-model="pw2"
              :error-messages="pw2Errors"
              :type="showConfirmPassword ? 'text' : 'password'"
              :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
              maxlength="50"
              @click:append="showConfirmPassword = !showConfirmPassword"
              @blur="$v.pw2.$touch()"
            ></v-text-field>
          </div>
        </v-form>
      </v-card>
      <div class="Text-Dark-Text-4---12-Left-Align ml-1 mt-2">
        {{ $t("reservation.required") }}
      </div>
      <v-card style="width: 100%" class="px-6" elevation="0">
        <v-card-actions v-if="!signUp">
          <v-btn
            width="100%"
            height="48"
            depressed
            color="primary"
            class="gradient mt-5 Heading-White-H4-18-Center"
            @click="update()"
            >{{ $t("reservation.completeProfileDone") }}</v-btn
          >
        </v-card-actions>
        <v-row v-if="signUp" dense class="ma-0">
          <v-col cols="12">
            <div class="Text-Dark-Text-3---14-Center">
              {{ $t("profile.acceptTerms") }}
              <a
                href="https://www.dynamic-deals.com/terms-of-use/"
                target="_system"
                @click="
                  openTerms('https://www.dynamic-deals.com/terms-of-use/')
                "
              >
                {{ $t("profile.terms") }}
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!$store.state.session.user" dense class="mx-3 mt-2 mb-n2">
          <v-col cols="12">
            <v-spacer></v-spacer>
            <div class="Text-Color-Text-3---14-Right-Align">
              <router-link to="/login-email">
                {{ $t("login.alreadyAccount") }}
              </router-link>
            </div>
          </v-col>
        </v-row>
        <v-card-actions v-if="signUp">
          <v-btn
            width="100%"
            height="48"
            depressed
            color="primary"
            class="gradient Heading-White-H4-18-Center"
            @click="register()"
            >{{ $t("onboarding.signUpAndBook") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import {
  required,
  sameAs,
  minLength,
  numeric,
  maxLength,
  helpers,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "CompleteProfile",
  validations: {
    firstName: {
      required,
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    lastName: {
      required,
      isValid: helpers.regex("isValid", /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_ ]*$/),
    },
    phone: {
      required,
      isValid: helpers.regex("isValid", /^\+[0-9]*$/),
      minLength: minLength(8),
    },
    pw1: { required, minLength: minLength(8) },
    pw2: { required, sameAsPassword: sameAs("pw1") },
    email: { required, email },
  },
  data: () => ({
    firstName: "",
    lastName: "",
    showPassword: false,
    showConfirmPassword: false,
    pw1: "",
    pw2: "",
    phone: "",
    email: "",
    alwaystrue: true,
  }),
  computed: {
    profile() {
      return this.$store.state.profile.data;
    },
    signUp() {
      return this.$store.state.isMiKY && !this.$store.state.session.user;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("profile.requireFirstname"));
      !this.$v.firstName.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "First name",
          })
        );
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("profile.requireLastname"));
      !this.$v.lastName.isValid &&
        errors.push(
          this.$t("profile.alphanumeric", {
            fieldName: "Last name",
          })
        );
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push(this.$t("profile.requirePhone"));
      !this.$v.phone.isValid && errors.push(this.$t("profile.validPhone"));
      !this.$v.phone.minLength &&
        errors.push(
          this.$t("profile.minLength", {
            fieldName: "Phone",
            minLength: "8",
          })
        );
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("profile.validMail"));
      !this.$v.email.required && errors.push(this.$t("profile.requireMail"));
      return errors;
    },
    pw1Errors() {
      const errors = [];
      if (!this.$v.pw1.$dirty) return errors;
      !this.$v.pw1.minLength && errors.push(this.$t("profile.atLeastChars"));
      !this.$v.pw1.required && errors.push(this.$t("profile.requirePassword"));
      return errors;
    },
    pw2Errors() {
      const errors = [];
      if (!this.$v.pw2.$dirty) return errors;
      !this.$v.pw2.sameAsPassword &&
        errors.push(this.$t("profile.notIdentical"));
      !this.$v.pw2.required &&
        errors.push(this.$t("profile.requireConfirmPassword"));
      return errors;
    },
  },
  methods: {
    update() {
      this.$v.$touch();

      if (
        this.$v.firstName.$invalid ||
        this.$v.lastName.$invalid ||
        this.$v.phone.$invalid
      ) {
        return;
      }

      this.profile.firstName = this.firstName;
      this.profile.lastName = this.lastName;
      this.profile.phone = this.phone;
      this.$store.dispatch("profile/updateProfile", {
        profile: this.profile,
      });
      this.$emit("completed");
    },
    async register() {
      this.$store.commit("session/CLEAR_AUTH_ERROR");
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const user = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        password: this.pw1,
      };
      await this.$store.dispatch("session/createUser", user);
      this.$emit("completed");
    },
  },
  mounted() {
    if (this.profile) {
      this.email = this.profile.email;
      this.firstName = this.profile.firstName;
      this.lastName = this.profile.lastName;
      this.phone = this.profile.phone;
    }
  },
};
</script>
