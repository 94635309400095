<template>
  <v-dialog v-model="alwaystrue" persistent>
    <v-card style="border-radius: 15px !important" class="pb-12">
      <v-toolbar flat
        ><v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn></v-toolbar
      >
      <v-card-title
        class="justify-center Text-Dark-Text-1---18-Center pt-12"
        style="word-break: break-word"
        >{{ $t("reservation.confirmCancel") }}</v-card-title
      >
      <v-card-actions class="justify-center pb-12 pt-7">
        <v-btn
          height="48"
          min-width="78"
          class="gradient Heading-White-H4-18-Left-Align mr-6"
          dark
          depressed
          @click="$emit('close')"
          >{{ $t("reservation.no") }}</v-btn
        >
        <v-btn
          height="48"
          min-width="78"
          class="gradient Heading-White-H4-18-Left-Align"
          dark
          depressed
          @click="$emit('cancelConfirmed')"
          >{{ $t("reservation.yes") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CancelBookingDialog",
  data: () => ({
    alwaystrue: true,
  }),
};
</script>
